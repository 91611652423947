import { createContext, useState, useMemo, useContext } from "react";
import {
  useGetIdentity,
  useRedirect,
  useGetList,
  TextField,
  Title,
  Labeled,
  SelectInput,
  SimpleForm
} from "react-admin";
import { Card, CardContent, Stack, Chip, Typography } from "@mui/material";
import styles from "./profile.module.css";
import { Account } from "../../generated";
import { LoaderLayout } from "../../components/LoaderLayout";
import { useApi } from "../../providers/env";

const ProfileContext = createContext({
  profileVersion: 0,
  refreshProfile: () => {},
});

export const ProfileProvider = ({ children }: { children: any }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => {
        setProfileVersion((currentVersion) => currentVersion + 1);
      },
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export const Profile = () => {
  const redirect = useRedirect();

  const { isLoading: isUserIdentityLoading, data } = useGetIdentity();
  console.log("🚀 ~ file: ProfileEdit.tsx:59 ~ ProfileEdit ~ data:", data);

  if (!isUserIdentityLoading && !data?.email) {
    redirect("/login");
  }

  const user = useMemo(
    () => ({
      fullName:
        data?.firstName && data?.lastName
          ? `${data.firstName} ${data.lastName}`
          : "",
      email: data?.email ?? "",
      client: data?.attributes.gelatoapi_client_id[0] ?? "",
      roles: data?.realm_access.roles ?? [],
      account: ""
    }),
    [data]
  );

  const accounts = useGetList<Account>(
      "accounts", { filter: { client_id: user.client }, pagination: { page: 1, perPage: 100 } }
  );

  const accountsApi = useApi().accountsApi;

  if (isUserIdentityLoading) {
    return <LoaderLayout />;
  }

  if (accounts.isLoading) {
    return <LoaderLayout />;
  }

  const onAccountSelected = (event: any) => {
    user.account = event.target.value;
    accountsApi.selectAccount({ accountId: Number(user.account) });
    console.log("🚀 ~ file: ProfileEdit.tsx:85 ~ ProfileEdit ~ onAccountSelected: account:", user.account);
  }

  return (
    <div>
      <Title title="Profile" />
      <SimpleForm>
        <Card className="mt-2 mb-2">
          <CardContent>
            <Stack spacing={1}>
              <Labeled label="Name">
                <TextField source="fullName" record={user} />
              </Labeled>
              <Labeled label="Email">
                <TextField source="email" record={user} />
              </Labeled>
              <Labeled label="Client">
                <TextField source="client" record={user} />
              </Labeled>
              <Labeled label="Account">
                <SelectInput source="account" choices={ accounts?.data?? undefined } onChange={ onAccountSelected }/>
              </Labeled>
              <div>
                <Typography color="textSecondary" className="RaLabeled-label">
                  Roles
                </Typography>
                {user.roles.map((role: string) => (
                  <Chip key={role} label={role} className={styles.role} />
                ))}
              </div>
            </Stack>
          </CardContent>
        </Card>
      </SimpleForm>
    </div>
  );
};
